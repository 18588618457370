import React from 'react';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import SubmitApplicationPublic from './SubmitApplicationPublic';
import AlreadyHaveAVulaAccount from './AlreadyHaveAVulaAccount';
import VulaLogo from '../../utils/VulaLogo';
import FlexibleSectionOfQuestions from '../FlexibleSectionOfQuestions';

export default function PublicApplicationForm() {
  const { applicationStepName, formDefinition } = React.useContext(
    ApplicationContextPublic,
  );

  if (!formDefinition) {
    return (
      <div className="w-full bg-white">
        <div className="rotate-[175deg] ">
          <VulaLogo />
        </div>
        <p className="text-2xl font-bold py-4">Something went wrong!</p>
      </div>
    );
  }

  return (
    <div className="py-2 p-1 shadow rounded-xl overflow-hidden bg-white w-full text-start h-auto transition-all duration-300">
      {applicationStepName === 'completed' ||
      applicationStepName === 'error-company-exists' ? null : (
        <div className="px-2 sm:px-4">
          <FlexibleSectionOfQuestions />
          <SubmitApplicationPublic />
        </div>
      )}

      {applicationStepName === '' ? (
        <>
          <div className="flex items-center w-full mt-4">
            <div className="w-full mx-4 border border-1 border-stone-100 border-solid rounded-xl " />
          </div>
          <AlreadyHaveAVulaAccount />
        </>
      ) : null}
    </div>
  );
}

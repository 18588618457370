import React from 'react';

import Loading from '../../../pages/Loading';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import VulaLogo from '../../utils/VulaLogo';
import PublicApplicationForm from './PublicApplicationForm';
import { track } from '../../../interfaces/mixpanel';

export default function PublicApplication() {
  const { formDefinition, publicFormIsLoading } = React.useContext(
    ApplicationContextPublic,
  );

  if (publicFormIsLoading) {
    return <Loading />;
  }

  if (!formDefinition || 'error' in formDefinition) {
    track('Public Form - no form found');
    return (
      <div className="w-full min-h-[100vh] bg-stone-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-xl shadow-lg p-4 sm:min-w-[900px] flex flex-col justify-center items-center">
          <div className="flex justify-center w-fit">
            <div className="rotate-[175deg] ">
              <VulaLogo />
            </div>
          </div>
          <p className="text-2xl font-bold py-4">No form found</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-[100vh] min-w-[100vw] h-full w-full"
      style={{ backgroundColor: formDefinition.bg_color }}
    >
      <div className="max-w-[1000px] m-auto p-1  flex flex-col items-center justify-center h-full">
        {/* SECTION 1 */}
        <PublicApplicationForm />
      </div>
    </div>
  );
}
